@import "../../sass/styles.scss";


.loader_container {
    margin: auto;
    border: 3px solid $white_200;
    border-top: 3px solid $primary_200;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.big {
    border: 6px solid $primary_200;
    border-top: 6px solid $primary_600;
    width: 30px;
    height: 30px;
}

.fallBack_cnt {
    @include center_middle_align();
    position: absolute;
    @include flex(row,wrap,center);
    gap: 12px;
}

.white_dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $white;
}

.green_cnt {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $primary_400;
    position: relative;
    animation: blink 1.5s infinite ease-in-out;
}

.mIcon {
    height: 20px;
    @include center_middle_align();
    position: absolute;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.message_container {
    margin: auto;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-family: $main_font;
    font-weight: 700;

    &.success {
        color: $primary_600;
    }

    &.contact {
        color: $white_200;
        font-size: 16px;
    }

    &.error {
        color: #ae1200;
    }
}

.deposit_container {
    padding: 10px 40px;
    margin: 30px auto;
    color: $primary_600;
    background-color: $white_200;
    font-family: $main_font;
    font-weight: 500;
    border-radius: 30px;
    text-align: start;

    @media #{$mobileP} {
        padding: 10px 20px;
    }
}

.qrCode_container {
    margin: 25px auto;
    font-family: $main_font;
    font-weight: 500;
}

.centerMessage {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 10px auto 20px;
    color: $primary_400;
}

.title {
    font-weight: 800;
    margin: 20px 0 30px;
    color: $black;
}

.subTitle {
    font-weight: 700;
}

.copyContainer {
    color: $primary_600;
    background-color: $white_200;
    border: 2px solid $primary_600;
    border-radius: 5px;
    padding: 2px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 1px 2px 0px $black;
}

.copyIcon {
    margin: 0 5px;
}