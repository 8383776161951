@mixin title ($color: $primary_400,
  $font-family: $main_font,
  $font-size: 18px,
  $font-weight: bold, $text-transform: uppercase, $letter-spacing: 0.5px) {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  text-transform: $text-transform;
  letter-spacing: $letter-spacing;
}

@mixin button($width: 150px, $height: 50px, $text-transform: none, $color: $white, $backgroundColor: $linear-light_green-gradient-rightAngle, $fontSize: 20px, $fontWeight: bold, $fontFamily: $main_font, $borderRadius: 100px, $borderColor: linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%), $borderWidth: none, $padding: 1px) {
  position: relative;
  width: $width;
  height: $height;
  font-size: $fontSize;
  color: $color;
  background: $backgroundColor;
  border: $borderWidth;
  border-radius: $borderRadius;
  font-family: $fontFamily;
  font-weight: $fontWeight;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-transform: $text-transform;
  box-shadow: 0 4px 4px 0 rgba($black, 0.25);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $borderRadius;
    padding: $padding;
    background: $borderColor;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
  &:hover {
    opacity: 0.8;
  }
}

@mixin buttonText($backgroundColor, $color) {
  background: $backgroundColor;
  -webkit-background-clip: text;
  color: $color;
  -webkit-text-fill-color: $color;
}

@mixin gradient-bg($type: linear, $directionOrShape: 45deg, $colors...) {
  @if $type ==linear {
    background: linear-gradient($directionOrShape, $colors...);
  }

  @else if $type ==radial {
    background: radial-gradient($directionOrShape, $colors...);
  }

  @else {
    @warn "Invalid gradient type specified. Use 'linear' or 'radial'.";
  }
}

@mixin borderBoxContainer($width, $height, $background, $border, $borderRadius, $inset, $borderBefore, $padding, $borderColor) {
  width: $width;
  height: $height;
  background: $background;
  position: relative;
  border-radius: $borderRadius;

  &::before {
    content: '';
    position: absolute;
    inset: $inset;
    border-radius: $borderRadius;
    padding: $padding;
    border: $borderBefore;
    background: $borderColor;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}

@mixin white_container($width, $height, $backgroundColor, $borderRadius, $borderBefore) {
  width: $width;
  height: $height;
  background-color: $backgroundColor;
  border-radius: $borderRadius;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 11px 9px;
    border: 1px dashed $borderBefore;
    border-radius: $borderRadius;
  }
}

@mixin wh-wrapper($width,$marginMobile:0) {
  background-color: $white_200;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: $width;
  height: 100%;
  margin: auto;
  box-shadow: 0 4px 4px 0 rgba(#000000, .25);
  padding: 37px 0;
  opacity: 95%;
  position: relative;

  @media #{$mobile} {
    width: 100%;
    padding: 22px 0 35px;
    margin-top: $marginMobile;
  }
}